<template>
  <div class="px-2" v-if="!props.collapsed">
    <sdButton
      class="switch-btn mb-4"
      :class="isBasket && 'icon-white'"
      size="small"
      :type="!isBasket ? 'dark' : 'primary'"
      :outlined="!isBasket"
      @click="click('basketView')"
    >
      {{ $t("generic.buttons.asset_basket") }}
      <i class="wiser-icon" :style="iconElement('/img/icons/cestaIcon.svg')"></i>
    </sdButton>
    <sdButton
      class="switch-btn"
      :class="isPortfolio && 'icon-white'"
      size="small"
      :type="isPortfolio ? 'primary' : 'dark'"
      :outlined="!isPortfolio"
      @click="click('portfolioView')"
    >
      {{ $t("generic.buttons.portfolio_summary") }}
      <i class="wiser-icon" :style="iconElement('/img/icons/carteraIcon.svg')"></i>
    </sdButton>
  </div>
  <div class="menu" v-else>
    <li class="nav-item">
      <div
        class="icono"
        :title="$t('generic.buttons.asset_basket')"
        v-tooltip="false"
        data-bs-placement="right"
        :style="iconElement('/img/icons/cestaIcon.svg')"
        @click="click('basketView')"
      ></div>
    </li>
    <li class="nav-item">
      <div
        class="icono"
        :title="$t('generic.buttons.portfolio_summary')"
        v-tooltip="false"
        data-bs-placement="right"
        :style="iconElement('/img/icons/carteraIcon.svg')"
        @click="click('portfolioView')"
      ></div>
    </li>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { usePortfolioStore } from "@/stores/PortfolioOverviewStore";
import { useBasketStore } from "@/stores/BasketStore";

const props = defineProps({
  collapsed: { type: Boolean, default: false },
});

const router = useRouter();
const route = useRoute();
const portfolioStore = usePortfolioStore();
const basketStore = useBasketStore();

const isPortfolio = computed(() => {
  return route.path.startsWith('/portfolio-overview');
});

const isBasket = computed(() => {
  return route.path.startsWith('/basket-assets') || route.path.startsWith('/search-select');
});

const iconElement = (icon) => {
  return {
    "-webkit-mask": "url(" + icon + ") no-repeat center",
    mask: "url(" + icon + ") no-repeat center",
  };
};

const click = async (view) => {
  if (view === "portfolioView") {
    console.log("portfolioStore.selectedPortfolio: ", portfolioStore.selectedPortfolio);
    if (portfolioStore.selectedPortfolio) {
      router.push({
        name: "portfolio-overview-ids",
        params: { ids: portfolioStore.selectedPortfolioIds.join(",") },
      });
    } else {
      router.push({ name: 'select-portfolio' });
    }
  } else {
    if (basketStore.fullData?.length > 0) {
      router.push({ name: 'basket-assets' });
    } else {
      router.push({ name: 'search-select' });
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/static/css/colors.scss";

.switch-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 80%;
  border-radius: 20px !important;
  padding: 0 !important;
  .wiser-icon {
    background-color: $text-light-color;
  }

  &:hover {
    background-color: $text-light-color;
    color: white;
    border: none;
    .wiser-icon {
      background-color: white;
    }  
  }
}

.icon-white {
  .wiser-icon {
    background-color: white;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  gap: 20px;
}

.wiser-icon {
  cursor: pointer;
}

.nav-item {
  margin: 0 !important;
  padding: 4px !important;
  cursor: pointer;
}
</style>
