<template>
  <InfoWraper>
    <div class="nav-author">
      <a-dropdown>
        <a class="ant-dropdown-link" @click.prevent>
          <a href="javascript:;"
            ><img :src="theFlag" alt="Idioma" class="theFlag"
          /></a>
        </a>
        <template #overlay>
          <NavAuth>
            <a
              v-for="lan in languages"
              :key="lan.locale"
              @click="() => onFlagChangeHandle(lan)"
              to="#"
            >
              <img :src="lan.url" alt="lan.alt" width="30" />
              <span>{{ lan.alt }}</span>
            </a>
          </NavAuth>
        </template>
      </a-dropdown>
    </div>
    <div class="nav-author">
      <sdPopover
        placement="bottomRight"
        action="click"
        :class="rtl ? 'ant-popover-rtl' : ''"
        style="width: 90%;"
        :getPopupContainer="triggerNode => triggerNode.parentNode"
      >
        <template v-slot:content>
          <UserDropDwon>
            <div class="user-dropdwon">
              <sdHeading as="h5">{{ userName }}</sdHeading>
              <ul class="user-dropdwon__links">
                <li>
                  <router-link to="/user-account-detail">
                    <sdFeatherIcons type="user" />
                    {{ $t("auth.profile_details") }}
                  </router-link>
                </li>
                <li>
                  <div
                    @click="copyAndShowSuccess"
                    class="custom-link"
                  >
                    <sdFeatherIcons type="bell" />
                    {{ "soporte@brokvisor.com" }}
                  </div>
                </li>
              </ul>
              <a @click="SignOut" class="user-dropdwon__bottomAction" href="#">
                <LogoutOutlined /> {{ $t("auth.log_out") }}
              </a>
            </div>
          </UserDropDwon>
        </template>
        <a to="#" class="head-example">
          <a-avatar>{{ initials }}</a-avatar>
        </a>
      </sdPopover>
    </div>
  </InfoWraper>
</template>

<script setup>
import { ref, onBeforeMount, computed } from "vue";
import { InfoWraper, UserDropDwon } from "./auth-info-style";
import { LogoutOutlined } from "@ant-design/icons-vue";
import { useAuthStore } from "@/stores/AuthStore";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import spainIcon from "@/components/icons/spain.png";
import gbIcon from "@/components/icons/great-britain.png";

const { t } = useI18n();

const { locale } = useI18n();

const initials = computed(() =>
  authStore.fullName ? authStore.fullName[0].toUpperCase() : "U"
);
const userName = computed(() =>
  authStore.fullName ? authStore.fullName : t("generic.titles.user")
);

onBeforeMount(async () => {
  await authStore.getUser();
  await authStore.getConfig();
});

const languages = [
  {
    url: spainIcon,
    alt: "Español",
    locale: "es",
    numberFormats: "es-ES",
    datetimeFormats: "es-ES",
  },
  {
    url: gbIcon,
    alt: "English",
    locale: "en",
    numberFormats: "en-EN",
    datetimeFormats: "en-EN",
  },
];

const authStore = useAuthStore();

const theFlag = ref(languages[0].url);

const SignOut = (e) => {
  e.preventDefault();
  locale.value = 'es';
  authStore.logOut();
};

const onFlagChangeHandle = (language) => {
  theFlag.value = language.url;
  locale.value = language.locale;
};

const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    console.error("Error al copiar al portapapeles:", err);
    return false;
  }
};

const copyAndShowSuccess = async () => {
  const emailToCopy = "soporte@brokvisor.com"; 
  const success = await copyToClipboard(emailToCopy);
  if (success) {
    message.success(t("auth.copy_to_clipboard"));
  }
};

</script>

<style scoped lang="scss">
.ant-avatar {
  &.ant-avatar-circle {
    :deep(.ant-avatar-string) {
      margin: 0 !important;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background: white;
    color: black;
  }
}

.custom-link {
  cursor: pointer;
  display: inline-block;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  sdFeatherIcons {
    margin-right: 5px;
  }
}

@media only screen and (max-width: 1149px) {
  .user-dropdwon {
    width: 100% !important;
    max-width: 100vw;
    margin: 10px;
  }
}
</style>
