<template>
    <div class="chat-interface">
      <div class="chat-wrapper" :class="{ 'active': showChat }" >
        <label class="chat-button" @click="showChat = !showChat">
          <img
            :src="require(`../../../static/img/chat-icon.svg`)"
            alt="chat-icon"
            width="16"
          />
        </label>
        <div class="chat-content">
          <div class="chat-content-header">
            <img
              :src="require(`../../../static/img/white-logo.svg`)"
              width="150"
              alt="logo"
            />
          </div>
          <div class="chat-content-body" id="chat-body">
            <div
              class="chat-message"
              :class="item.sender"
              v-for="(item, index) in authStore.messages"
              :key="index"
            >
              <small style="padding: 0 8px;">
                {{
                  moment(item.datetime).isAfter(moment().subtract(3, 'day')) ?
                  moment(item.datetime).fromNow() :
                  moment(item.datetime).format('YYYY-MM-DD HH:mm')
                }}</small>
              <p>
                {{ item.content }}
              </p>
            </div>
            <div class="chat-message bot" v-if="!!authStore.loadingQuestion">
              <p class="d-flex justify-content-center">
                <a-spin :indicator="indicatorSmall"></a-spin>
              </p>
            </div>
          </div>
          <div class="chat-content-footer">
            <a-input
              class="question-input"
              size="small"
              type="text"
              v-model:value="question"
              :placeholder="$t('generic.titles.chat_input_placeholder')"
              @focus="focusedInput = true"
              @blur="focusedInput = false"
            ></a-input>
            <label @click="giveFeedback(true)">
            <img
              :src="require(`../../../static/img/icon/like.svg`)"
              alt="like"
              width="24"
              height="24"
            />
            </label>
            <label @click="giveFeedback(false)">
              <img
              :src="require(`../../../static/img/icon/like.svg`)"
              alt="dislike"
              width="24"
              height="24"
              style="transform: rotate(180deg);"
            />
            </label>
          </div>
        </div>
      </div>
    </div>
</template>
<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useAuthStore } from "../../../stores/AuthStore";
import { LoadingOutlined } from '@ant-design/icons-vue';
import { h } from 'vue';
import moment from 'moment';

const indicatorSmall = h(LoadingOutlined, {
    style: {
      padding: '0px 1px 4px 1px',
      fontSize: '12px',
    },
    spin: true,
});

const authStore = useAuthStore();

const showChat = ref(false);
const question = ref('');
const focusedInput = ref(false);

const scrollToBottom = () => {
    const element = document.getElementById('chat-body');
    element.scrollTop = element.scrollHeight;
};

const askQuestion = () => {
  const questionStr = question.value;
  question.value = '';
  if (questionStr) {
      authStore.messages.push({
        sender: 'me',
        content: questionStr,
        datetime: new Date()
      });
      scrollToBottom();
      authStore.loadingQuestion = true;
      authStore.askQuestion(questionStr).then((response) => {
        authStore.loadingQuestion = false;
        authStore.messages.push({
          sender: 'bot',
          content: response.data.data,
          datetime: new Date()
        });
      })
      .catch((err) => {
        console.error(err);
        authStore.loadingQuestion = false;
        authStore.messages.push({
          sender: 'bot',
          content: 'Sorry, I am not sure for that. :(',
          datetime: new Date()
        });
      });
    }
};

// const like = async () => {
//   await authStore.askQuestion('feedback', true);
// };

// const dislike = async () => {
//   await authStore.askQuestion('feedback', false);
// };

const giveFeedback = async (feedback) => {
  scrollToBottom();
  authStore.messages.push({
    sender: 'me',
    content: feedback ? '👍' : '👎',
    datetime: new Date()
  });
  await authStore.askQuestion('feedback', feedback)
    .then((response) => {
      authStore.loadingQuestion = false;
      authStore.messages.push({
        sender: 'bot',
        content: response.data.data,
        datetime: new Date()
      });
    });
};

const handleClickEvent = (evt) => {
    if (!evt.target.closest('.chat-interface') && showChat.value) {
      showChat.value = false;
    }
};

const handleKeydown = (e) => {
  if (e.keyCode === 13 && focusedInput.value) {
    askQuestion();
  }
};

watch(
    () => showChat.value,
    () => {
      question.value = '';
    }
);

onMounted(async () => {
  await authStore.getChatHistory();
  
  document.addEventListener('click', handleClickEvent);
  document.addEventListener('keydown', handleKeydown);
});

onUnmounted(() => {
    document.removeEventListener('click', handleClickEvent);
    document.removeEventListener('keydown', handleKeydown);
});
</script>
<style scoped lang="scss">
.layout {
    position: relative;
    .chat-interface {
      z-index: 999;
      .chat-wrapper {
        position: relative;
        .chat-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: white;
          cursor: pointer;
          box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
          transition: all 0.1s linear;
          scale: 1;
          &:hover {
            transform: scale(1);
          }
        }
        &.active {
          .chat-button {
            transform: scale(1.1);
          }
        }
        .chat-content {
          position: absolute;
          width: 360px;
          height: 480px;
          background-color: white;
          border-radius: 8px;
          box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
          right: -8px;
          top: 16px;
          visibility: hidden;
          opacity: 0;
          transition: all 0.2s linear;
          display: flex;
          flex-direction: column;
          .chat-content-header {
            display: flex;
            align-items: center;
            padding-left: 4px;
            height: 40px;
            background-color: #004851;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }
          .chat-content-body {
            flex-grow: 1;
            height: 0;
            padding: 16px;
            overflow-y: auto;
            .chat-message {
              font-size: 14px;
              display: flex;
              flex-direction: column;
              margin-bottom: 12px;
              p {
                border-radius: 4px;
                padding: 8px 12px;
                margin: 0 !important;
              }
              &.bot {
                justify-content: right;
                align-items: flex-end;
                p {
                  background-color: rgb(250, 244, 237);
                }
              }
              &.me {
                justify-content: flex-start;
                align-items: flex-start;
                p {
                  background-color: #dfe9f2;
                }
              }
            }
          }
          .chat-content-footer {
            height: 40px;
            padding: 0 4px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            border-top: 1px solid lightgray;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            .question-input {
              width: 75%;
              height: 75%;
              border-color: lightgray !important;
              border-radius: 4px !important;
              &:focus {
                border-color: #02B388 !important;
              }
            }
            label {
              border-radius: 4px;
              padding: 1px 4px;
              cursor: pointer;
              &:hover {
                background-color: rgba(0, 0, 0, 0.05);
              }
            }
          }
        }
        &.active {
          .chat-content {
            opacity: 1;
            visibility: visible;
            transform: translateY(5%);
          }
        }
      }
    }
}
</style>