<template>
  <div class="menu">
    <br />
    <div class="menu-submenus">
      <div>
        <h5 class="label">{{ $t("sidebar.portfolio_management") }}</h5>
        <ul>
          <sidebar-item
            v-for="item in portfolioItems"
            :key="item.title"
            :item="item"
          ></sidebar-item>
        </ul>
      </div>

      <div>
        <h5 class="label" v-html="$t('sidebar.search_assets')"></h5>
        <ul>
          <sidebar-item
            v-for="item in assetsItems"
            :key="item.title"
            :item="item"
          ></sidebar-item>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
import VueTypes from "vue-types";
import SidebarItem from "@/components/wiseral/sidebar/SidebarItem";
import { useAuthStore } from "@/stores/AuthStore";
import Restrictions from "../config/restriction/restrict_functions";

defineProps({
  events: {
    type: Object,
    default: VueTypes.object,
  },
});

const authStore = useAuthStore();

const portfolioItems = computed(() => {
  let items = [
    {
      icon: "/img/icons/generateIcon.svg",
      title: "sidebar.portfolio_generate",
      destination:
        authStore.role_id >= 2
          ? "/generate-portfolio-advanced"
          : "/generate-portfolio",
    },
    {
      icon: "/img/icons/cargarIcon.svg",
      title: "sidebar.my_portfolio",
      destination: "/load-portfolio",
    },
    {
      icon: "/img/icons/importarIcon.svg",
      title: "sidebar.portfolio_import",
      destination: "/import-portfolio",
    },
  ];
  const restrictions = authStore.restrictions;
  if (restrictions.includes(Restrictions["PortfolioGeneration"])) {
    items =  items.filter(item => item.destination !== "/generate-portfolio");
  }
  if (restrictions.includes(Restrictions["AdvancedPortfolioGeneration"])) {
    items =  items.filter(item => item.destination !== "/generate-portfolio-advanced");
  }
  if (restrictions.includes("portfolio_load")) {
    items =  items.filter(item => item.destination !== "/load-portfolio");
  }
  if (restrictions.includes("portfolio_import")) {
    items =  items.filter(item => item.destination !== "/import-portfolio");
  } 
  return items;
});
const assetsItems = computed (() => {
  let items = [
    {
      icon: "/img/icons/fondosIcon.svg",
      title: "sidebar.funds",
      destination: "/search-funds",
    },
    {
      icon: "/img/icons/accionesIcon.svg",
      title: "sidebar.stocks",
      destination: "/search-stocks",
    },
    {
      icon: "/img/icons/cryptosIcon.svg",
      title: "sidebar.cryptos",
      destination: "/search-cryptos",
    },
    {
      icon: "/img/icons/pinBenchmarkIcon.svg",
      title: "sidebar.benchmark_search",
      destination: "/search-benchmarks",
    },
    {
      icon: "/img/icons/etfIcon.svg",
      title: "sidebar.etf_search",
      destination: "/search-etfs",
    },
    {
      icon: "/img/icons/pensionIcon.svg",
      title: "sidebar.pensions",
      destination: "/search-pensions",
    },
  ];
  const restrictions = authStore.restrictions;
  if (restrictions.includes(Restrictions["ViewFunds"])) {
    items = items.filter(item => item.destination !== "/search-funds");
  }
  if (restrictions.includes("view_stocks")) {
    items = items.filter(item => item.destination !== "/search-stocks");
  }
  if (restrictions.includes(Restrictions["ViewCryptos"])) {
    items = items.filter(item => item.destination !== "/search-cryptos");
  }
  if (restrictions.includes(Restrictions["ViewBenchmarks"])) {
    items = items.filter(item => item.destination !== "/search-benchmarks");
  }
  if (restrictions.includes(Restrictions["ViewEtfs"])) {
    items = items.filter(item => item.destination !== "/search-etfs");
  } 
  if (restrictions.includes(Restrictions["ViewPensions"])) {
    items = items.filter(item => item.destination !== "/search-pensions");
  }  
  return items;
});
</script>

<style lang="scss">
@import "src/static/css/colors";
.menu {
  .label {
    font-size: 1em;
    color: $text-dark-color;
    font-weight: bold;
    overflow-wrap: break-word;
    padding: 10px 8px;
  }
}
.ant-layout-sider {
  .burguer {
    float: right;
  }
}
.ant-layout-sider-collapsed {
  .burguer {
    margin: 0em 0.9em;
    float: none;
  }

  h5 {
    display: none;
  }
  .nav-item {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
    span.text {
      display: none !important;
    }
  }
}

.menu-submenus {
  & > * {
    margin: 10px 0 20px 0;
  }
}
</style>
